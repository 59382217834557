<template>
  <div>
    <!--Breadcrumb-->
    <section>
      <Breadcrumb title="Şehir Listesi" :array="[{islink:true,url:'/',text:'Ana Sayfa'},{islink:false,url:'', text:'Şehir Listesi'}]"/>
    </section>
    <!--/Breadcrumb-->
    <section class="sptb"  style="min-height:590px">
      <div class="container">
        <div class="row">
          <template v-if="filterList.length == 0 && !isLoad">
            <div class="row text-center">
              <h2>Şehir Bulunamadı!</h2>
            </div>
          </template>
          <template v-if="isLoad">
            <div class="row text-center">
              <h2>Şehir Listesi Getiriliyor...</h2>
            </div>
          </template>
          <div
            class="col-lg-3 col-md-12"
            v-for="(item, i) in filterList"
            :key="'cityCard' + i"
          >
            <router-link :to="'/city/' + item.shortname">
              <div class="card">
                <div class="card-body">
                  <div class="item-box text-center">
                    <div
                      class="
                        bg-primary-transparent
                        icon-bg icon-service
                        text-primary
                      "
                    >
                      <i class="fa fa-map-marker"></i>
                    </div>
                    <div class="item-box-wrap">
                      <h5 class="mb-2">{{ item.name }}</h5>
                      <p class="text-muted mb-0"></p>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
          <div class="center-block text-center">
            <ul class="pagination mb-5" style="overflow-x: scroll">
              <li
                v-for="(item, i) in alphabet"
                :key="'alphapet' + i"
                class="page-item m-1"
              >
                <button class="page-link" @click="filter(item)">
                  {{ item }}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Breadcrumb from '../components/Breadcrumb.vue';
export default {
  metaInfo() {
    return {
      title: "Şehir Listesi - Terapi Vitrini",
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            "Terapi Vitrini Şehir Listesi - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz",
        },
      ],
    };
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
    this.$store.dispatch("setMetaContent", [
      "Şehir Listesi - Terapi Vitrini",
      "Terapi Vitrini Şehir Listesi - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz",
      "",
      "",
      "",
    ]);
    let cityInfo = {};
    this.$store.dispatch("citysGet", cityInfo).then((value) => {
      this.cityList = value.list;
      this.filter(this.default);
      this.isLoad = false;
    });
  },
  data() {
    return {
      isLoad: true,
      default: "A",
      alphabet: [
        "A",
        "B",
        "C",
        "Ç",
        "D",
        "E",
        "F",
        "G",
        "H",
        "İ",
        "I",
        "K",
        "L",
        "M",
        "N",
        "O",
        "Ö",
        "P",
        "R",
        "S",
        "Ş",
        "T",
        "U",
        "Ü",
        "V",
        "Y",
        "Z",
      ],
      filterList: [],
      cityList: [],
    };
  },
  components: { Breadcrumb },
  methods: {
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
    filter(item) {
      this.filterList = this.cityList.filter((country) =>
        country.name.startsWith(item)
      );
    },
  },
  mounted() {
    this.backToTop();
  },
};
</script>
        